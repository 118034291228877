export const leftDecorators = [
  '\u2605\u00b7.\u00b7\u00b4\u00af`\u00b7.\u00b7\u2605',
  '\u00b0\u00b0\u00b0\u00b7.\u00b0\u00b7..\u00b7\u00b0\u00af\u00b0\u00b7._.\u00b7',
  '\u00b8,\u00f8\u00a4\u00ba\u00b0`\u00b0\u00ba\u00a4\u00f8,\u00b8\u00b8,\u00f8\u00a4\u00ba\u00b0',
  '\u0131ll\u0131ll\u0131',
  '\u2022?((\u00af\u00b0\u00b7._.\u2022',
  '\u00d7\u00ba\u00b0\u201d\u02dc`\u201d\u00b0\u00ba\u00d7',
  '\u2022]\u2022\u2022\u00b4\u00ba\u00b4\u2022\u00bb',
  '*\u2022.\u00b8\u2661',
  '\u2570\u2606\u2606',
  '.\u2022\u00b0\u00a4*(\u00af`\u2605\u00b4\u00af)*\u00a4\u00b0',
  '(\u00af\u00b4\u2022._.\u2022',
  '\u00b8\u201e.-\u2022~\u00b9\u00b0\u201d\u02c6\u02dc\u00a8',
  '\u2591\u2592\u2593\u2588',
  '\u2591\u2592\u2593\u2588\u25ba\u2500\u2550',
  '\u2605\u5f61',
  '\u2022\u00b4\u00af`\u2022.',
  "\u00a7.\u2022\u00b4\u00a8'\u00b0\u00f7\u2022..\u00d7",
  '\u2022\u00b0\u00af`\u2022\u2022',
  '(\u00af`*\u2022.\u00b8,\u00a4\u00b0\u00b4\u273f.\uff61.:*',
  "|!\u00a4*'~``~'*\u00a4!|",
  '\u2022._.\u2022\u2022\u00b4\u00af``\u2022.\u00b8\u00b8.\u2022`',
  '\u00b8\u201e.-\u2022~\u00b9\u00b0\u201d\u02c6\u02dc\u00a8',
  '(\u00af\u00b4\u2022._.\u2022',
  '\u2022\u2022\u00a4(`\u00d7[\u00a4',
  '\u2022\u00b4\u00af`\u2022\u00bb',
  '.o0\u00d7X\u00d70o.',
  '\u00a4\u00b8\u00b8.\u2022\u00b4\u00af`\u2022\u00b8\u00b8.\u2022..>>',
  '\u2014(\u2022\u2022\u00f7[',
  '\u00b8,\u00f8\u00a4\u00ba\u00b0`\u00b0\u00ba\u00a4\u00f8,\u00b8',
  '`\u2022.\u00b8\u00b8.\u2022\u00b4\u00b4\u00af`\u2022\u2022._.\u2022',
  ",-*' ^ '~*-.,_,.-*~",
  '`\u2022.,\u00b8\u00b8,.\u2022\u00b4\u00af',
  '\u21a4\u21a4\u21a4\u21a4\u21a4',
  '\u27b6\u27b6\u27b6\u27b6\u27b6',
  '\u21ab\u21ab\u21ab\u21ab\u21ab',
  '\u00b7.\u00b8\u00b8.\u00b7\u2669\u266a\u266b',
  '\u3010\uff61_\uff61\u3011',
  ']|I{\u2022------\u00bb',
  '\u2580\u2584\u2580\u2584\u2580\u2584',
  '(-_-)',
  '\u2022\u00b4\u00af`\u2022.',
  "-\u6f2b~*'\u00a8\u00af\u00a8'*\u00b7\u821e~",
  '\u0e51\u06de\u0e51,\u00b8\u00b8,\u00f8\u00a4\u00ba\u00b0`\u00b0\u0e51\u06e9',
  '.\u2022\u00b0\u00a4*(\u00af`\u2605\u00b4\u00af)*\u00a4\u00b0',
  '\u2022\u2022.\u2022\u00b4\u00af`\u2022.\u2022\u2022',
  '\u00a4\u00b8\u00b8.\u2022\u00b4\u00af`\u2022\u00b8\u00b8.\u2022..>>',
  '\u25e6\u2022\u25cf\u25c9\u273f',
  '\u255a\u00bb\u2605\u00ab\u255d',
  '-\u00b7=\u00bb\u2021\u00ab=\u00b7-',
  '\u2219\u2219\u00b7\u25ab\u25ab\u1d52\u1d3c\u1d52\u25ab\u2092\u2092\u25ab\u1d52\u1d3c\u1d52\u25ab\u2092\u2092\u25ab\u1d52\u1d3c\u1d52',
  '\u00b8\u00b8\u266c\u00b7\u00af\u00b7\u2669\u00b8\u00b8\u266a\u00b7\u00af\u00b7\u266b\u00b8\u00b8',
  '\u0b9c\u06e9\u06de\u06e9\u0b9c',
  '\u00a4 (\u00af\u00b4\u2606\u272d.\u00b8_)\u00a4',
  '(\u00af`\u00b7.\u00b8\u00b8.\u00b7\u00b4\u00af`\u00b7.\u00b8\u00b8.->',
  '\u273f.\uff61.:* \u2606:**:.',
  '.\u2022\u266b\u2022\u266c\u2022',
  '\u10e6(\u00af`\u25d5\u203f\u25d5\u00b4\u00af) \u266b \u266a \u266b',
  '\u00ab-(\u00af`v\u00b4\u00af)-\u00ab',
];

export const rightDecorators = [
  '\u2605\u00b7.\u00b7\u00b4\u00af`\u00b7.\u00b7\u2605',
  '\u00b7._.\u00b7\u00b0\u00af\u00b0\u00b7.\u00b7\u00b0 .\u00b7\u00b0\u00b0\u00b0',
  '\u00b0\u00ba\u00a4\u00f8,\u00b8\u00b8,\u00f8\u00a4\u00ba\u00b0`\u00b0\u00ba\u00a4\u00f8,\u00b8',
  '\u0131ll\u0131ll\u0131',
  '\u2022._.\u00b7\u00b0\u00af))\u061f\u2022',
  '\u00d7\u00ba\u00b0\u201d\u02dc`\u201d\u00b0\u00ba\u00d7',
  '\u00ab\u2022\u00b4\u00ba\u00b4\u2022\u2022[\u2022',
  '\u2661\u00b8.\u2022*',
  '\u2606\u2606\u256e',
  '\u00b0\u00a4*(\u00af\u00b4\u2605`\u00af)*\u00a4\u00b0\u2022.',
  '\u00a8\u02dc\u02c6\u201d\u00b0\u00b9~\u2022-.\u201e\u00b8',
  '\u2588\u2593\u2592\u2591',
  '\u2550\u2500\u25c4\u2588\u2593\u2592\u2591',
  '\u5f61\u2605',
  '.\u2022\u00b4\u00af`\u2022',
  "\u00d7,.\u2022\u00b4\u00a8'\u00b0\u00f7\u2022..\u00a7",
  '\u2022\u2022\u00b4\u00af\u00b0\u2022',
  '*.:\uff61.\u273f`\u00b0\u00a4,\u00b8.\u2022*\u00b4\u00af)',
  "|!\u00a4*'~``~'*\u00a4!|",
  '`\u2022.\u00b8\u00b8.\u2022\u00b4\u00b4\u00af`\u2022\u2022._.\u2022',
  '\u00a8\u02dc\u02c6\u201d\u00b0\u00b9~\u2022-.\u201e\u00b8',
  '\u2022._.\u2022\u00b4\u00af)',
  '\u00a4]\u00d7\u00b4)\u00a4\u2022\u2022',
  '\u00ab\u2022\u00b4\u00af`\u2022',
  '.o0\u00d7X\u00d70o.',
  '<<..\u2022.\u00b8\u00b8\u2022\u00b4\u00af`\u2022.\u00b8\u00b8\u00a4',
  '\u00f7\u2022\u2022)\u2014',
  '\u00b8,\u00f8\u00a4\u00ba\u00b0`\u00b0\u00ba\u00a4\u00f8,\u00b8',
  '\u2022._.\u2022\u2022`\u00af\u00b4\u00b4\u2022.\u00b8\u00b8.\u2022`',
  "~*-.,_,.-*~' ^ '*-,",
  '\u00af`\u2022.,\u00b8\u00b8,.\u2022\u00b4',
  '\u21a6\u21a6\u21a6\u21a6\u21a6',
  '\u27b7\u27b7\u27b7\u27b7\u27b7',
  '\u21ac\u21ac\u21ac\u21ac\u21ac',
  '\u266b\u266a\u2669\u00b7.\u00b8\u00b8.\u00b7',
  '\u3010\uff61_\uff61\u3011',
  '\u00ab------\u2022}I|[',
  '\u2584\u2580\u2584\u2580\u2584\u2580',
  '(-_-)',
  '.\u2022\u00b4\u00af`\u2022',
  "~\u821e*'\u00a8\u00af\u00a8'*\u00b7~\u6f2b-",
  '\u0e51\u06e9 ,\u00b8\u00b8,\u00f8\u00a4\u00ba\u00b0`\u00b0\u0e51\u06de\u0e51',
  '\u00b0\u00a4*(\u00af\u00b4\u2605`\u00af)*\u00a4\u00b0\u2022.',
  '\u2022\u2022.\u2022\u00b4\u00af`\u2022.\u2022\u2022',
  '<<..\u2022.\u00b8\u00b8\u2022\u00b4\u00af`\u2022.\u00b8\u00b8\u00a4',
  '\u273f\u25c9\u25cf\u2022\u25e6',
  '\u255a\u00bb\u2605\u00ab\u255d',
  '-\u00b7=\u00bb\u2021\u00ab=\u00b7-',
  '\u1d52\u1d3c\u1d52\u25ab\u2092\u2092\u25ab\u1d52\u1d3c\u1d52\u25ab\u2092\u2092\u25ab\u1d52\u1d3c\u1d52\u25ab\u25ab\u00b7\u2219\u2219',
  '\u00b8\u00b8\u266b\u00b7\u00af\u00b7\u266a\u00b8\u00b8\u2669\u00b7\u00af\u00b7\u266c\u00b8\u00b8',
  '\u0b9c\u06e9\u06de\u06e9\u0b9c',
  '\u00a4(_\u00b8.\u272d\u2606\u00b4\u00af) \u00a4',
  '<-.\u00b8\u00b8.\u00b7\u00b4\u00af`\u00b7.\u00b8\u00b8.\u00b7\u00b4\u00af)',
  '.:**:.\u2606*.:\uff61.\u273f',
  '\u2022\u266c\u2022\u266b\u2022.',
  '\u266b \u266a \u266b (\u00af`\u25d5\u203f\u25d5\u00b4\u00af)\u10e6',
  '\u00bb-(\u00af`v\u00b4\u00af)-\u00bb',
];
