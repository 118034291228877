import { Listbox, Transition } from '@headlessui/react';

import * as React from 'react';
import { leftDecorators, rightDecorators } from './decoratorsArrays';

export interface IDropdownProps {
  right: boolean;
  setDecorator: (context: string) => void;
  className?: any;
}

export function Dropdown({ right, setDecorator, className }: IDropdownProps) {
  const [selectedDecorator, setSelectedDecorator] = React.useState('');
  const decorators = right ? leftDecorators : rightDecorators;

  React.useEffect(() => {
    setDecorator(selectedDecorator);
  }, [selectedDecorator]);

  return (
    <div className={className}>
      <div className="rounded-l w-full h-full">
        <Listbox
          as="div"
          className="h-full rounded-l"
          value={selectedDecorator}
          onChange={setSelectedDecorator}
        >
          {({ open }) => (
            <>
              <div className="relative h-full rounded-l md:min-w-208px">
                <span className="inline-block w-full h-full rounded-l">
                  <Listbox.Button
                    className={`${
                      right ? 'rounded-l' : 'rounded-r'
                    } h-full relative w-full  bg-white pr-10 px-20px pt-36px pb-16px text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                  >
                    {/* {!right && (
                      <span className="absolute inset-y-0 left-0 flex items-center pr-20px pointer-events-none">
                        <svg
                          width="17"
                          height="10"
                          viewBox="0 0 17 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.5 10L17 0H0L8.5 10Z" fill="#1967FA" />
                        </svg>
                      </span>
                    )} */}

                    <span className="block truncate text-text">
                      {selectedDecorator}
                    </span>
                    {/* {right && ( */}
                    <span className="absolute inset-y-0 right-0 flex items-center pr-20px pointer-events-none">
                      <svg
                        width="17"
                        height="10"
                        viewBox="0 0 17 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.5 10L17 0H0L8.5 10Z" fill="#1967FA" />
                      </svg>
                    </span>
                    {/* )} */}
                    <div className=" dropdown-label absolute mb-0 leading-tighter text-gray-400 text-dec cursor-text">
                      {!right ? 'Справа' : 'Слева'}
                    </div>
                  </Listbox.Button>
                </span>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1  rounded bg-white"
                >
                  <Listbox.Options
                    static
                    className="mt-2 rounded max-h-60 py-1 text-text leading-6 shadow-my overflow-auto focus:outline-none"
                  >
                    {decorators.map((decorator, i) => (
                      <Listbox.Option key={i} value={decorator}>
                        {({ selected, active }) => (
                          <div
                            className={`${
                              active
                                ? 'text-white bg-blue-600'
                                : 'text-gray-900'
                            } cursor-default select-none relative py-2 pl-8 pr-4`}
                          >
                            <span
                              className={`${
                                selected ? 'font-semibold' : 'font-normal'
                              } block truncate`}
                            >
                              {decorator}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? 'text-white' : 'text-blue-600'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}
