/* eslint-disable quotes */
import React from 'react';
import { PageProps } from 'gatsby';
import { transliterate as tr } from 'transliteration';
// import { generate, loadMoreFonts } from '../components/igfonts';
import 'react-toastify/dist/ReactToastify.css';
// import fontsDescr from '../data/graveyard.json';

import { Dropdown } from '@/components/Dropdown';
import { fontsMaps } from '@/components/fontsMaps';
import Layout from '@/components/Layout';
import FullWidthButton from '@/components/FullWidthButton';
import { FontGrid } from '@/components/fontGrid';
import _ from 'lodash';
import { useFontsDescr } from '@/hooks/useFontDescr';
import { Helmet } from 'react-helmet';

const Home: React.FC<PageProps> = ({ location, pageContext }) => {
  const [text, setText] = React.useState(`Пример текста`);
  const [decoratorL, setDecoratorL] = React.useState('');
  const [decoratorR, setDecoratorR] = React.useState('');

  const { nodes } = useFontsDescr();

  const setDecoratorL$ = (context: string) => {
    setDecoratorL(context);
  };

  const setDecoratorR$ = (context: string) => {
    setDecoratorR(context);
  };

  const [list, setList] = React.useState(
    Object.entries(fontsMaps).map((s) => {
      const font = _.find(nodes, ['id', String(s[0])]);
      return {
        id: font.id,
        name: font.name,
        text: decoratorL + ' ' + s[1](tr(text)) + ' ' + decoratorR,
      };
    }),
  );

  React.useEffect(() => {
    setList(
      Object.entries(fontsMaps).map((s) => {
        const font = _.find(nodes, ['id', String(s[0])]);
        return {
          id: font.id,
          name: font.name,
          text: decoratorL + ' ' + s[1](tr(text)) + ' ' + decoratorR,
        };
      }),
    );
  }, [decoratorL, decoratorR, text]);

  // const loadMore = () => {
  //   setList((old) => old.concat(loadMoreFonts(text)));
  // };

  return (
    <>
      <Layout
        header={'Красивые шрифты онлайн'}
        descr={
          'Введите текст, скопируйте понравившийся шрифт либо отредактируйте его, <br /> добавив больше крутых символов'
        }
        showFooter={true}
      >
        <Helmet>
          {/* <meta charSet="utf-8" /> */}
          <title>{`Красивые шрифты онлайн. Напиши и скопируй 🤩 | PrettyFont.net`}</title>
          <link rel="canonical" href={location.href} />
          <meta
            property="og:title"
            content={`Красивые шрифты онлайн. Напиши и скопируй 🤩 | PrettyFont.net`}
          />
          <meta
            name="description"
            content={`🤩 Красивые шрифты для социальных сетей (Instagramm, VK, Twitter, Facebook и других). Лучшие шрифты для ников. На сайте вы можете написать текст и скопировать прямо онлайн онлайн.`}
          />
          <meta
            property="og:description"
            content={`🤩 Красивые шрифты для социальных сетей (Instagramm, VK, Twitter, Facebook и других). Лучшие шрифты для ников. На сайте вы можете написать текст и скопировать прямо онлайн онлайн.`}
          />
          <meta property="og:url" content={location.href} />
          {/* <meta property="og:image:width" content="279" /> */}
          {/* <meta property="og:image:height" content="279" /> */}
          {/* <meta
          property="og:image"
          content="https://textgenerator.ru/assets/favicons/og-image.jpg"
        /> */}
        </Helmet>
        <div className="flex justify-center sticky top-3 mb-60px z-10">
          <div className="flex-auto p-big bg-gradient-to-r from-my-blue to-my-violet rounded-12px shadow-my max-w-max-input">
            <div className="bg-white h-full rounded-8px flex divide-x flex-wrap	md:flex-nowrap">
              <Dropdown
                right={true}
                setDecorator={setDecoratorL$}
                className=" order-2 md:order-1 w-1/2 "
              />
              <div className="w-full pb-big bg-gradient-to-r from-my-blue to-my-violet rounded-t-12px shadow-my max-w-max-input md:order-2 md:bg-white md:pb-0 md:rounded-t-none	">
                <div className="relative w-full bg-white rounded-t-12px md:rounded-t-none	">
                  <input
                    className="filled input appearance-none w-full px-20px pt-36px pb-16px focus focus:outline-none active:outline-none text-grey-darker text-text rounded-t-8px md:rounded-t-none"
                    id="text"
                    type="text"
                    autoFocus
                    value={text}
                    onChange={(evt) => {
                      setText(evt.target.value);
                    }}
                  />
                  <label
                    htmlFor="text"
                    className="label absolute mb-0 leading-tighter text-gray-400 cursor-text text-dec"
                  >
                    Введите текст
                  </label>
                </div>
              </div>
              <Dropdown
                right={false}
                setDecorator={setDecoratorR$}
                className="order-3 w-1/2"
              />
            </div>
          </div>
        </div>
        <FontGrid list={list} original={text} />
        {/* <FullWidthButton text="Загрузить еще красивых шрифтов" /> */}
      </Layout>
    </>
  );
};

export default Home;
